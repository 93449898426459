<template>
  <div>
    <portal to="v-main"> </portal>
    <v-card-text style="height: 300px; overflow: auto" class="pa-0">
      <v-simple-table dense class="block_master_stat">
        <thead>
          <tr>
            <td :style="`font-weight: 500; height:  22px`"></td>
            <td class="text-center font-weight-bold px-0 mx-1" :style="`height: 22px; `">Покупок</td>
            <td class="text-center font-weight-bold px-0 mx-1" :style="`height: 22px; `">Сумма (план)</td>
          </tr>
        </thead>
        <tbody v-if="loaded">
          <tr v-for="(row, i) in items" :key="i">
            <td class="text-left" :style="{ 'font-weight': 500, height: '38px', 'white-space': 'normal' }">
              <component :is="'a-view-select'" :model="{ name: 'status', type: 'select', options: statuses }" :value="row.status" />
            </td>
            <td class="text-center px-0 pl-2" :style="`height: 38px; `">
              <component :is="'a-view-number'" :model="{ name: 'cc', type: 'number' }" :value="row['cc']" />
            </td>
            <td class="text-right px-0 pr-2" :style="`height: 38px; `">
              <component :is="'a-view-number'" :model="{ name: 'price', type: 'number' }" :value="row['price']" />
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>
  </div>
</template>

<script>
import { getAccess } from "@/components/mixings";
export default {
  mixins: [getAccess],
  components: {},
  props: {
    id: Number,
    data: Object,
  },
  data() {
    return {
      loaded: false,
      items: [],
      statuses: [
        { value: -3, text: "Ошибка задачи" },
        { value: -2, text: "Черновик" },
        { value: -1, text: "Настройка" },
        { value: 0, text: "В задачах" },
        { value: 200, text: "Новая задача", status: "new" },
        { value: 201, text: "Ожидает закупа", status: "progress" },
        { value: 205, text: "Ожидает перезакупа", status: "progress" },
        { value: 202, text: "Закуплен (не доставлен)", status: "progress" },
        { value: 203, text: "На складе", status: "ready" },
        { value: 204, text: "На объекте", status: "ready" },
        { value: 299, text: "Отменено", status: "cancel" },
        { value: 298, text: "Требуется замена", status: "cancel" },
        { value: 220, text: "Отправить в заказ", status: "progress" },
        { value: 280, text: "В заказе на закуп", status: "progress" },
        { value: 281, text: "Закуплен (в заказе)", status: "progress" },
      ],
    };
  },
  created() {
    this.fitchData();
  },
  watch: {},
  computed: {
    model() {
      let model = [
        { name: "status", type: "select", options: this.statuses },
        { name: "cc", type: "number" },
        { name: "price", type: "number" },
      ];

      return model;
    },
  },
  methods: {
    async fitchData() {
      let resp = await this.$axios.post("/report_sql", { name: "getObjectConstructionGoodsSummary", params: { object_id: this.id } });
      this.items = [...resp.data.data];
      this.loaded = true;
    },
  },
};
</script>

<style lang="sass">
.v-slide-group1__prev--disabled
  display: none !important
.v-slide-group1__next--disabled
  display: none !important
</style>
